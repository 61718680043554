import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_USERS,
  GET_RAW_USERS,
  GET_USER_DETAILS,
  UPDATE_USER,
  DELETE_USER,
  GET_USER_CARTS,
  GET_USER_CART_DETAILS,
  DELETE_USER_CART,
  GET_USER_FAVOURITE_DETAILS,
  GET_USER_RECENT_VIEWS,
  DELETE_USER_FAVOURITE,
  GET_USER_ADDRESS,
  ADD_USER_ADDRESS,
  DELETE_USER_ADDRESS,
  ADD_ITEM_TO_USER_CART,
  ADD_COUPON_TO_USER_CART,
  CREATE_USER,
} from "./actionTypes"
import {
  getUsersSuccess,
  getUsersFail,
  getRawUsersSuccess,
  getRawUsersFail,
  getUserDetailsSuccess,
  getUserDetailsFail,
  updateUserSuccess,
  updateUserFail,
  deleteUserSuccess,
  deleteUserFail,
  getUserCartsSuccess,
  getUserCartsFail,
  getUserCartDetailsSuccess,
  getUserCartDetailsFail,
  deleteUserCartSuccess,
  deleteUserCartFail,
  getUserFavouriteDetailsSuccess,
  getUserFavouriteDetailsFail,
  getUserRecentViewsSuccess,
  getUserRecentViewsFail,
  deleteUserFavouriteSuccess,
  deleteUserFavouriteFail,
  getUserAddressSuccess,
  getUserAddressFail,
  addUserAddressSuccess,
  addUserAddressFail,
  deleteUserAddressSuccess,
  deleteUserAddressFail,
  addItemToUserCartSuccess,
  addItemToUserCartFail,
  addCouponToUserCartSuccess,
  addCouponToUserCartFail,
  createUserSuccess,
  createUserFail,
} from "./actions"
import { get, post, ApiPut, del } from "helpers/api_helper"
import {
  Notification,
  useSuccessNotification,
} from "../../components/Common/Notification"

function getUsersAPi({ page, sort, limit, searchText }) {
  if (searchText) {
    return get(`/user/admin/custom/${sort ? sort : "all"}?search=${searchText}`)
  }
  return get(
    `/user/admin/custom/${sort ? sort : "all"}?page=${page ? page : 1}&limit=${
      limit || 10
    }`
  )
}

function getRawUsersAPi({ searchText }) {
  // if (searchText) {
  //   return get(`/user/admin/custom/${sort ? sort : "all"}?search=${searchText}`)
  // }
  return get(`/user/admin/raw/all?search=${searchText}`)
}

function getCartsAPi() {
  return get("/cart/admin/all")
}

const getUserDetailsAPi = userId => {
  return get(`/user/admin/${userId}`)
}

const updateUserApi = ({ user, userId }) => {
  return ApiPut(`/user/admin/${userId}`, user)
}

const deleteUserApi = userId => {
  return del(`/user/admin/${userId}`)
}
const deleteUserCartApi = userId => {
  return del(`/cart/admin/${userId}`)
}

const getUserCartDetailsAPi = userId => {
  return get(`/cart/admin/user/${userId}`)
}

const createUserApi = ({ user }) => {
  return post(`/user/admin/new`, user)
}
const getUserFavouriteDetailsAPi = userId => {
  return get(`/favourite/admin/userId/${userId}`)
}

const getUserRecentViewsAPi = userId => {
  return get(`/recent-view/admin/userId/${userId}`)
}
function getUserAddressApi(userId) {
  return get(`/user/admin/address/${userId}`)
}
function addUserAddressApi({ address, userId }) {
  return post(`/user/admin/address/new/${userId}`, address)
}
function deleteUserAddressApi({ addressId, userId }) {
  return del(`/user/admin/address/${userId}/${addressId}`)
}

function addItemToUserCartAPi({ userId, item }) {
  return post(`/cart/admin/add-remove/${userId}`, item)
}
function addCouponToUserCartAPi({ userId, coupon }) {
  // /coupon/admin/validate/:user
  return post(`/coupon/admin/validate/${userId}`, coupon)
}

function* fetchUsers({ payload }) {
  try {
    const response = yield call(getUsersAPi, payload)
    // const cresponse = yield call(getCartsAPi) //payload
    yield put(getUsersSuccess(response))
    // yield put(getUserCartsSuccess(cresponse))
  } catch (error) {
    yield put(getUsersFail(error))
    yield put(getUserCartsFail(error))
  }
}

function* fetchRawUsers({ payload }) {
  try {
    const response = yield call(getRawUsersAPi, payload)
    yield put(getRawUsersSuccess(response))
  } catch (error) {
    yield put(getRawUsersFail(error))
  }
}

function* fetchUserDetail({ payload: userId }) {
  try {
    const response = yield call(getUserDetailsAPi, userId)
    yield put(getUserDetailsSuccess(response))
  } catch (error) {
    yield put(getUserDetailsFail(error))
  }
}

function* fetchUserCartDetail({ payload: userId }) {
  try {
    const response = yield call(getUserCartDetailsAPi, userId)
    yield put(getUserCartDetailsSuccess(response))
  } catch (error) {
    yield put(getUserCartDetailsFail(error))
  }
}

function* fetchUserFavouriteDetail({ payload: userId }) {
  try {
    const response = yield call(getUserFavouriteDetailsAPi, userId)
    yield put(getUserFavouriteDetailsSuccess(response))
  } catch (error) {
    yield put(getUserFavouriteDetailsFail(error))
  }
}

function* fetchUserRecentViews({ payload: userId }) {
  try {
    const response = yield call(getUserRecentViewsAPi, userId)
    yield put(getUserRecentViewsSuccess(response))
  } catch (error) {
    yield put(getUserRecentViewsFail(error))
  }
}

function* onCreateUser({ payload }) {
  try {
    const response = yield call(createUserApi, payload)
    yield put(createUserSuccess(response))
    Notification({
      type: "success",
      message: "User Created Successfully!",
      title: "",
    })
    if (payload?.closeModal) {
      payload?.closeModal()
    }
  } catch (error) {
    console.log(error.response?.data?.message)
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(createUserFail(error.response?.data?.message))
  }
}

function* onUpdateUser({ payload }) {
  try {
    const response = yield call(updateUserApi, payload)
    yield put(updateUserSuccess(response))

    Notification({
      type: "success",
      message: "User Updated Successfully!",
      title: "",
    })
    if (payload.history) {
      payload.history.goBack()
    }
  } catch (error) {
    console.log(error)
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    yield put(updateUserFail(error))
  }
}

function* onDeleteUser({ payload: { userId } }) {
  try {
    const response = yield call(deleteUserApi, userId)
    yield put(deleteUserSuccess(response, userId))
    Notification({
      type: "success",
      message: "User Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    yield put(deleteUserFail(error))
  }
}
function* onDeleteUserCart({ payload: { userId } }) {
  try {
    const cartResponse = yield call(deleteUserCartApi, userId)
    yield put(deleteUserCartSuccess(cartResponse))

    Notification({
      type: "success",
      message: "User Deleted Successfully!",
      title: "",
    })
    // history.goBack()
  } catch (error) {
    yield put(deleteUserCartFail(error))
  }
}

//address
function* fetchUserAddress({ payload }) {
  try {
    const response = yield call(getUserAddressApi, payload)
    if (response) {
      yield put(getUserAddressSuccess(response))
    }
  } catch (error) {
    yield put(getUserAddressFail(error))
  }
}

function* addUserAddress({ payload }) {
  try {
    const response = yield call(addUserAddressApi, payload)
    if (response) {
      yield put(addUserAddressSuccess(response))
      payload.onCloseClick()
      useSuccessNotification("Delivery address added")
    }
  } catch (error) {
    yield put(addUserAddressFail(error))
  }
}

function* deleteUserAddress({ payload }) {
  try {
    const response = yield call(deleteUserAddressApi, payload)
    if (response) {
      yield put(deleteUserAddressSuccess(payload.addressId))
      useSuccessNotification("Delivery address deleted")
      useSuccessNotification()
    }
  } catch (error) {
    yield put(deleteUserAddressFail(error))
  }
}

function* addItemToUserCart({ payload }) {
  try {
    const response = yield call(addItemToUserCartAPi, payload)
    yield put(addItemToUserCartSuccess(response))
    useSuccessNotification()
  } catch (error) {
    yield put(addItemToUserCartFail(error))
  }
}
function* addCouponToUserCart({ payload }) {
  try {
    const response = yield call(addCouponToUserCartAPi, payload)
    yield put(addCouponToUserCartSuccess(response))
    useSuccessNotification()
  } catch (error) {
    yield put(addCouponToUserCartFail(error))
  }
}

function* userSaga() {
  yield takeEvery(GET_USERS, fetchUsers)
  yield takeEvery(GET_RAW_USERS, fetchRawUsers)
  yield takeEvery(GET_USER_CARTS, fetchUsers)
  yield takeEvery(GET_USER_DETAILS, fetchUserDetail)
  yield takeEvery(GET_USER_CART_DETAILS, fetchUserCartDetail)
  yield takeEvery(GET_USER_FAVOURITE_DETAILS, fetchUserFavouriteDetail)
  yield takeEvery(GET_USER_RECENT_VIEWS, fetchUserRecentViews)
  yield takeEvery(UPDATE_USER, onUpdateUser)
  yield takeEvery(CREATE_USER, onCreateUser)
  yield takeEvery(DELETE_USER, onDeleteUser)
  yield takeEvery(DELETE_USER_CART, onDeleteUserCart)
  yield takeEvery(GET_USER_ADDRESS, fetchUserAddress)
  yield takeEvery(ADD_USER_ADDRESS, addUserAddress)
  yield takeEvery(DELETE_USER_ADDRESS, deleteUserAddress)
  yield takeEvery(ADD_ITEM_TO_USER_CART, addItemToUserCart)
  yield takeEvery(ADD_COUPON_TO_USER_CART, addCouponToUserCart)
}

export default userSaga

//
