import {
  GET_ORDERS,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAIL,
  GET_ORDER_DETAILS,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAIL,
  GET_USER_ORDER_DETAILS,
  GET_USER_ORDER_DETAILS_SUCCESS,
  GET_USER_ORDER_DETAILS_FAIL,
  UPDATE_ORDER_ITEM,
  UPDATE_ORDER_ITEM_SUCCESS,
  UPDATE_ORDER_ITEM_FAIL,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAIL,
  GET_STARRED_ORDERS,
  GET_STARRED_ORDERS_SUCCESS,
  GET_STARRED_ORDERS_FAIL,
  GET_CANCELLED_ORDERS,
  GET_CANCELLED_ORDERS_SUCCESS,
  GET_CANCELLED_ORDERS_FAIL,
  GET_RETURNED_ORDERS,
  GET_RETURNED_ORDERS_SUCCESS,
  GET_RETURNED_ORDERS_FAIL,
  UPDATE_PAYMENT,
  UPDATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_FAIL,
  UPDATE_ORDER_AWB_NUMBER,
  UPDATE_ORDER_AWB_NUMBER_SUCCESS,
  UPDATE_ORDER_AWB_NUMBER_FAIL,
  ADD_ITEM_TO_USER_CART,
  ADD_ITEM_TO_USER_CART_SUCCESS,
  ADD_ITEM_TO_USER_CART_FAIL,
  CREATE_USER_ORDER,
  CREATE_USER_ORDER_SUCCESS,
  CREATE_USER_ORDER_FAIL,
  CREATE_SHIPROCKET_ORDER,
  CREATE_SHIPROCKET_ORDER_SUCCESS,
  CREATE_SHIPROCKET_ORDER_FAIL,
  CLEAR_ORDER,
  GET_TOTAL_ORDER_COUNTS,
  GET_TOTAL_ORDER_COUNTS_SUCCESS,
  GET_TOTAL_ORDER_COUNTS_FAIL,
  GET_SHIPROCKET_LABEL,
  GET_SHIPROCKET_LABEL_SUCCESS,
  GET_SHIPROCKET_LABEL_FAIL,
  GET_SHIPROCKET_INVOICE_SUCCESS,
  GET_SHIPROCKET_INVOICE,
  GET_SHIPROCKET_INVOICE_FAIL,
  ORDER_AUTO_STATUS_UPDATE,
  ORDER_AUTO_STATUS_UPDATE_SUCCESS,
  ORDER_AUTO_STATUS_UPDATE_FAIL,
  CLEAR_AUTO_STATUS_UPDATE,
  CREATE_ORDER_EDIT,
  CREATE_ORDER_EDIT_SUCCESS,
  CREATE_ORDER_EDIT_FAIL,
} from "./actionTypes"

//all orders
export const getOrders = (page, limit, searchText, sort, sortByDate) => ({
  type: GET_ORDERS,
  payload: { page, limit, searchText, sort, sortByDate },
})

export const getOrdersSuccess = orders => ({
  type: GET_ORDERS_SUCCESS,
  payload: orders,
})

export const getOrdersFail = error => ({
  type: GET_ORDERS_FAIL,
  payload: error,
})

//order details
export const getOrderDetails = orderId => ({
  type: GET_ORDER_DETAILS,
  payload: orderId,
})

export const getOrderDetailsSuccess = orderDetails => ({
  type: GET_ORDER_DETAILS_SUCCESS,
  payload: orderDetails,
})

export const getOrderDetailsFail = error => ({
  type: GET_ORDER_DETAILS_FAIL,
  payload: error,
})

//USER order details
export const getUserOrderDetails = (userId, page) => ({
  type: GET_USER_ORDER_DETAILS,
  payload: { userId, page },
})

export const getUserOrderDetailsSuccess = userOrderDetails => ({
  type: GET_USER_ORDER_DETAILS_SUCCESS,
  payload: userOrderDetails,
})

export const getUserOrderDetailsFail = error => ({
  type: GET_USER_ORDER_DETAILS_FAIL,
  payload: error,
})

//Update ITEM
export const updateOrderItem = (order, orderId, history) => ({
  type: UPDATE_ORDER_ITEM,
  payload: { order, orderId, history },
})

export const updateOrderItemSuccess = order => ({
  type: UPDATE_ORDER_ITEM_SUCCESS,
  payload: order,
})

export const updateOrderItemFail = error => ({
  type: UPDATE_ORDER_ITEM_FAIL,
  payload: error,
})

//UPDATE ORDER
export const updateOrder = (order, history) => ({
  type: UPDATE_ORDER,
  payload: { order, history },
})

export const updateOrderSuccess = order => ({
  type: UPDATE_ORDER_SUCCESS,
  payload: order,
})

export const updateOrderFail = error => ({
  type: UPDATE_ORDER_FAIL,
  payload: error,
})

export const updatePayment = order => ({
  type: UPDATE_PAYMENT,
  payload: { order },
})

export const updatePaymentSuccess = order => ({
  type: UPDATE_PAYMENT_SUCCESS,
  payload: order,
})

export const updatePaymentFail = error => ({
  type: UPDATE_PAYMENT_FAIL,
  payload: error,
})

export const updateOrderAwb = order => ({
  type: UPDATE_ORDER_AWB_NUMBER,
  payload: { order },
})

export const updateOrderAwbSuccess = order => ({
  type: UPDATE_ORDER_AWB_NUMBER_SUCCESS,
  payload: order,
})

export const updateOrderAwbFail = error => ({
  type: UPDATE_ORDER_AWB_NUMBER_FAIL,
  payload: error,
})

//all starred orders
export const getStarredOrders = page => ({
  type: GET_STARRED_ORDERS,
  payload: { page },
})

export const getStarredOrdersSuccess = starredOrders => ({
  type: GET_STARRED_ORDERS_SUCCESS,
  payload: starredOrders,
})

export const getStarredOrdersFail = error => ({
  type: GET_STARRED_ORDERS_FAIL,
  payload: error,
})

//all cancelled orders
export const getCancelledOrders = page => ({
  type: GET_CANCELLED_ORDERS,
  payload: { page },
})

export const getCancelledOrdersSuccess = cancelledOrders => ({
  type: GET_CANCELLED_ORDERS_SUCCESS,
  payload: cancelledOrders,
})

export const getCancelledOrdersFail = error => ({
  type: GET_CANCELLED_ORDERS_FAIL,
  payload: error,
})

//all returned orders
export const getReturnedOrders = page => ({
  type: GET_RETURNED_ORDERS,
  payload: { page },
})

export const getReturnedOrdersSuccess = returnedOrders => ({
  type: GET_RETURNED_ORDERS_SUCCESS,
  payload: returnedOrders,
})

export const getReturnedOrdersFail = error => ({
  type: GET_RETURNED_ORDERS_FAIL,
  payload: error,
})
//create user order
export const createUserOrders = (orderData, history) => ({
  type: CREATE_USER_ORDER,
  payload: { orderData, history },
})

export const createUserOrdersSuccess = orders => ({
  type: CREATE_USER_ORDER_SUCCESS,
  payload: orders,
})

export const createUserOrdersFail = error => ({
  type: CREATE_USER_ORDER_FAIL,
  payload: error,
})

export const createShiprocketOrder = data => ({
  type: CREATE_SHIPROCKET_ORDER,
  payload: { data },
})

export const createShiprocketOrderSuccess = order => ({
  type: CREATE_SHIPROCKET_ORDER_SUCCESS,
  payload: order,
})

export const createShiprocketOrderFail = error => ({
  type: CREATE_SHIPROCKET_ORDER_FAIL,
  payload: error,
})

export const clearOrders = () => ({
  type: CLEAR_ORDER,
})

export const getTotalOrderCounts = (searchText, sortByDate) => ({
  type: GET_TOTAL_ORDER_COUNTS,
  payload: { searchText, sortByDate },
})
export const getTotalOrderCountsSuccess = res => ({
  type: GET_TOTAL_ORDER_COUNTS_SUCCESS,
  payload: res,
})
export const getTotalOrderCountsFail = error => ({
  type: GET_TOTAL_ORDER_COUNTS_FAIL,
  payload: error,
})

export const getShiprocketLabel = shiprocketId => ({
  type: GET_SHIPROCKET_LABEL,
  payload: shiprocketId,
})
export const getShiprocketLabelSuccess = res => ({
  type: GET_SHIPROCKET_LABEL_SUCCESS,
  payload: res,
})
export const getShiprocketLabelFail = error => ({
  type: GET_SHIPROCKET_LABEL_FAIL,
  payload: error,
})

export const getShiprocketInvoice = shiprocketId => ({
  type: GET_SHIPROCKET_INVOICE,
  payload: shiprocketId,
})
export const getShiprocketInvoiceSuccess = res => ({
  type: GET_SHIPROCKET_INVOICE_SUCCESS,
  payload: res,
})
export const getShiprocketInvoiceFail = error => ({
  type: GET_SHIPROCKET_INVOICE_FAIL,
  payload: error,
})

export const autoUpdateOrderStatus = status => ({
  type: ORDER_AUTO_STATUS_UPDATE,
  payload: status,
})
export const autoUpdateOrderStatusSuccess = res => ({
  type: ORDER_AUTO_STATUS_UPDATE_SUCCESS,
  payload: res,
})
export const autoUpdateOrderStatusFail = error => ({
  type: ORDER_AUTO_STATUS_UPDATE_FAIL,
  payload: error,
})
export const clearAutoStatusUpdate = () => ({
  type: CLEAR_AUTO_STATUS_UPDATE,
})

//CREATE ORDER EDIT
export const overwriteOrderDetails = (order, onCloseClick) => ({
  type: CREATE_ORDER_EDIT,
  payload: { order, onCloseClick },
})

export const overwriteOrderDetailsSuccess = order => ({
  type: CREATE_ORDER_EDIT_SUCCESS,
  payload: order,
})

export const overwriteOrderDetailsFail = error => ({
  type: CREATE_ORDER_EDIT_FAIL,
  payload: error,
})
