import MetaTag from "components/Common/Meta-tag"
import React, { useEffect } from "react"

import ShopDetail from "./Details"
import { clearOrders } from "store/actions"
import { useDispatch } from "react-redux"

function ShopDetails() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearOrders())
  }, [dispatch])

  return (
    <>
      <MetaTag title={"Order Details"} />
      <ShopDetail />
    </>
  )
}

export default ShopDetails
