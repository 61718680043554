import {
  GET_NOTIFICATION,
  GET_NOTIFICATION_FAIL,
  GET_NOTIFICATION_SUCCESS,
} from "./actionTypes"

//all settings
export const getNotifications = page => ({
  type: GET_NOTIFICATION,
  payload: { page },
})

//all settings
export const getNotificationSuccess = notifications => ({
  type: GET_NOTIFICATION_SUCCESS,
  payload: notifications,
})

export const getNotificationFail = error => ({
  type: GET_NOTIFICATION_FAIL,
  payload: error,
})
