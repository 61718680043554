import {
  GET_SHOP_PRODUCTS,
  GET_SHOP_PRODUCTS_SUCCESS,
  GET_SHOP_PRODUCTS_FAIL,
  GET_DELETED_SHOP_PRODUCTS,
  GET_DELETED_SHOP_PRODUCTS_SUCCESS,
  GET_DELETED_SHOP_PRODUCTS_FAIL,
  GET_SHOP_PRODUCT_DETAILS,
  GET_SHOP_PRODUCT_DETAILS_SUCCESS,
  GET_SHOP_PRODUCT_DETAILS_FAIL,
  CREATE_SHOP_PRODUCT,
  CREATE_SHOP_PRODUCT_SUCCESS,
  CREATE_SHOP_PRODUCT_FAIL,
  UPDATE_SHOP_PRODUCT,
  UPDATE_SHOP_PRODUCT_SUCCESS,
  UPDATE_SHOP_PRODUCT_FAIL,
  CHANGE_SHOP_PRODUCT_STATUS,
  CHANGE_SHOP_PRODUCT_STATUS_SUCCESS,
  CHANGE_SHOP_PRODUCT_STATUS_FAIL,
  DELETE_SHOP_PRODUCT,
  DELETE_SHOP_PRODUCT_SUCCESS,
  DELETE_SHOP_PRODUCT_FAIL,
  HARD_DELETE_SHOP_PRODUCT,
  HARD_DELETE_SHOP_PRODUCT_SUCCESS,
  HARD_DELETE_SHOP_PRODUCT_FAIL,
  RESTORE_SHOP_PRODUCT,
  RESTORE_SHOP_PRODUCT_SUCCESS,
  RESTORE_SHOP_PRODUCT_FAIL,
} from "./actionTypes"

export const getShopProducts = page => ({
  type: GET_SHOP_PRODUCTS,
  payload: { page },
})

export const getShopProductsSuccess = shopProducts => ({
  type: GET_SHOP_PRODUCTS_SUCCESS,
  payload: shopProducts,
})

export const getShopProductsFail = error => ({
  type: GET_SHOP_PRODUCTS_FAIL,
  payload: error,
})

export const getDeletedShopProducts = () => ({
  type: GET_DELETED_SHOP_PRODUCTS,
})

export const getDeletedShopProductsSuccess = shopProducts => ({
  type: GET_DELETED_SHOP_PRODUCTS_SUCCESS,
  payload: shopProducts,
})

export const getDeletedShopProductsFail = error => ({
  type: GET_DELETED_SHOP_PRODUCTS_FAIL,
  payload: error,
})

export const getShopProductDetails = shopProductId => ({
  type: GET_SHOP_PRODUCT_DETAILS,
  payload: shopProductId,
})

export const getShopProductDetailsSuccess = shopProductDetails => ({
  type: GET_SHOP_PRODUCT_DETAILS_SUCCESS,
  payload: shopProductDetails,
})

export const getShopProductDetailsFail = error => ({
  type: GET_SHOP_PRODUCT_DETAILS_FAIL,
  payload: error,
})

export const createShopProduct = (shopProduct, history) => ({
  type: CREATE_SHOP_PRODUCT,
  payload: { shopProduct, history },
})

export const createShopProductSuccess = shopProduct => ({
  type: CREATE_SHOP_PRODUCT_SUCCESS,
  payload: shopProduct,
})

export const createShopProductFail = error => ({
  type: CREATE_SHOP_PRODUCT_FAIL,
  payload: error,
})

export const updateShopProduct = (shopProduct, shopProductId, history) => ({
  type: UPDATE_SHOP_PRODUCT,
  payload: { shopProduct, shopProductId, history },
})

export const updateShopProductSuccess = shopProduct => ({
  type: UPDATE_SHOP_PRODUCT_SUCCESS,
  payload: shopProduct,
})

export const updateShopProductFail = error => ({
  type: UPDATE_SHOP_PRODUCT_FAIL,
  payload: error,
})

export const changeShopProductStatus = (shopProductId, history) => ({
  type: CHANGE_SHOP_PRODUCT_STATUS,
  payload: { shopProductId, history },
})

export const changeShopProductStatusSuccess = shopProduct => ({
  type: CHANGE_SHOP_PRODUCT_STATUS_SUCCESS,
  payload: shopProduct,
})

export const changeShopProductStatusFail = error => ({
  type: CHANGE_SHOP_PRODUCT_STATUS_FAIL,
  payload: error,
})

export const deleteShopProduct = (shopProductId, history) => ({
  type: DELETE_SHOP_PRODUCT,
  shopProductId,
  history,
})

export const deleteShopProductSuccess = shopProduct => ({
  type: DELETE_SHOP_PRODUCT_SUCCESS,
  payload: shopProduct,
})

export const deleteShopProductFail = error => ({
  type: DELETE_SHOP_PRODUCT_FAIL,
  payload: error,
})

export const hardDeleteShopProduct = (shopProductId, history) => ({
  type: HARD_DELETE_SHOP_PRODUCT,
  shopProductId,
  history,
})

export const hardDeleteShopProductSuccess = shopProduct => ({
  type: HARD_DELETE_SHOP_PRODUCT_SUCCESS,
  payload: shopProduct,
})

export const hardDeleteShopProductFail = error => ({
  type: HARD_DELETE_SHOP_PRODUCT_FAIL,
  payload: error,
})

export const restoreShopProduct = (shopProductId, history) => ({
  type: RESTORE_SHOP_PRODUCT,
  shopProductId,
  history,
})

export const restoreShopProductSuccess = shopProduct => ({
  type: RESTORE_SHOP_PRODUCT_SUCCESS,
  payload: shopProduct,
})

export const restoreShopProductFail = error => ({
  type: RESTORE_SHOP_PRODUCT_FAIL,
  payload: error,
})
