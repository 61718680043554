import {
  GET_STORES,
  GET_STORES_SUCCESS,
  GET_STORES_FAIL,
  GET_STORE_LIST,
  GET_STORE_LIST_SUCCESS,
  GET_STORE_LIST_FAIL,
  GET_UNAPPROVED_STORES,
  GET_UNAPPROVED_STORES_SUCCESS,
  GET_UNAPPROVED_STORES_FAIL,
  GET_BANNED_STORES,
  GET_BANNED_STORES_SUCCESS,
  GET_BANNED_STORES_FAIL,
  CHANGE_STORE_STATUS,
  CHANGE_STORE_STATUS_SUCCESS,
  CHANGE_STORE_STATUS_FAIL,
  GET_STORE_DETAILS,
  GET_STORE_DETAILS_SUCCESS,
  GET_STORE_DETAILS_FAIL,
  CREATE_STORE,
  CREATE_STORE_SUCCESS,
  CREATE_STORE_FAIL,
  UPDATE_STORE,
  UPDATE_STORE_SUCCESS,
  UPDATE_STORE_FAIL,
  DELETE_STORE,
  DELETE_STORE_SUCCESS,
  DELETE_STORE_FAIL,
} from "./actionTypes"

export const getStores = page => ({
  type: GET_STORES,
  payload: { page },
})

export const getStoresSuccess = stores => ({
  type: GET_STORES_SUCCESS,
  payload: stores,
})

export const getStoresFail = error => ({
  type: GET_STORES_FAIL,
  payload: error,
})

export const getStoreList = () => ({
  type: GET_STORE_LIST,
})

export const getStoreListSuccess = storeList => ({
  type: GET_STORE_LIST_SUCCESS,
  payload: storeList,
})

export const getStoreListFail = error => ({
  type: GET_STORE_LIST_FAIL,
  payload: error,
})

export const getUnapprovedStores = page => ({
  type: GET_UNAPPROVED_STORES,
  payload: { page },
})

export const getUnapprovedStoresSuccess = stores => ({
  type: GET_UNAPPROVED_STORES_SUCCESS,
  payload: stores,
})

export const getUnapprovedStoresFail = error => ({
  type: GET_UNAPPROVED_STORES_FAIL,
  payload: error,
})

export const getBannedStores = page => ({
  type: GET_BANNED_STORES,
  payload: { page },
})

export const getBannedStoresSuccess = stores => ({
  type: GET_BANNED_STORES_SUCCESS,
  payload: stores,
})

export const getBannedStoresFail = error => ({
  type: GET_BANNED_STORES_FAIL,
  payload: error,
})

export const changeStoreStatus = history => ({
  type: CHANGE_STORE_STATUS,
  payload: { history },
})

export const changeStoreStatusSuccess = store => ({
  type: CHANGE_STORE_STATUS_SUCCESS,
  payload: store,
})

export const changeStoreStatusFail = error => ({
  type: CHANGE_STORE_STATUS_FAIL,
  payload: error,
})

export const getStoreDetails = storeId => ({
  type: GET_STORE_DETAILS,
  payload: storeId,
})

export const getStoreDetailsSuccess = storeDetails => ({
  type: GET_STORE_DETAILS_SUCCESS,
  payload: storeDetails,
})

export const getStoreDetailsFail = error => ({
  type: GET_STORE_DETAILS_FAIL,
  payload: error,
})

export const createStore = (store, history) => ({
  type: CREATE_STORE,
  payload: { store, history },
})

export const createStoreSuccess = store => ({
  type: CREATE_STORE_SUCCESS,
  payload: store,
})

export const createStoreFail = error => ({
  type: CREATE_STORE_FAIL,
  payload: error,
})

// export const getShopProductDetail = productId => ({
//   type: GET_SHOP_PRODUCT_DETAILS,
//   productId,
// })

export const updateStore = (store, storeId, history) => ({
  type: UPDATE_STORE,
  payload: { store, storeId, history },
})

export const updateStoreSuccess = store => ({
  type: UPDATE_STORE_SUCCESS,
  payload: store,
})

export const updateStoreFail = error => ({
  type: UPDATE_STORE_FAIL,
  payload: error,
})

export const deleteStore = (storeId, history) => ({
  type: DELETE_STORE,
  storeId,
  history,
})

export const deleteStoreSuccess = (store, history) => ({
  type: DELETE_STORE_SUCCESS,
  payload: { store, history },
})

export const deleteStoreFail = error => ({
  type: DELETE_STORE_FAIL,
  payload: error,
})
